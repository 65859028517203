main {
    padding: 12px;
}

.paperfield {
    margin-bottom: 16px;
}

.paperfield__label {
    display: block;
    margin-bottom: 2px;
    font-weight: bold;
}

.paperfield__label.required:after {
    content: '*';
    color: red;
  }

.paperfield__desc {
    margin: 0;
    margin-bottom: 2px;
}

input[type="text"] {
    width: 70%;
}

textarea {
    width: 80%;
    height: 100px;
}