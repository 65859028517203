.container {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 16px;
}


.logo {
    width: 200px;
    height: auto;
    margin-bottom: 25px;
}

.login-button {
    background: #221665;
    color: white;
    padding: 8px;
    border: none;
    font-size: 1.5em;
    width: 128px;
}