.container {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 16px;
}

.logo {
    width: 124px;
    height: auto;
    margin-bottom: 25px;
}

.submit-button {
    background: #221665;
    color: white;
    padding: 8px;
    border: none;
    font-size: 1em;
    /* width: 128px; */
}
